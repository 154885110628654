import { ACCESS_TOKEN_SLUG, REFRESH_TOKEN_SLUG } from "@/constants";

export const setAccessToken = (token) => {
  return localStorage.setItem(ACCESS_TOKEN_SLUG, token);
};

export const setRefreshToken = (token) => {
  return localStorage.setItem(REFRESH_TOKEN_SLUG, token);
};

export const getAccessToken = () => {
  return localStorage.getItem(ACCESS_TOKEN_SLUG);
};

export const getRefreshToken = () => {
  return localStorage.getItem(REFRESH_TOKEN_SLUG);
};

export const removeAccessToken = () => {
  return localStorage.removeItem(ACCESS_TOKEN_SLUG);
};

export const removeRefreshToken = () => {
  return localStorage.getItem(REFRESH_TOKEN_SLUG);
};
