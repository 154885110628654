import Vue from "vue";
import VueRouter from "vue-router";
import OrderView from "@/views/OrderView.vue";
import { getAccessToken } from "@/helpers";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "order",
    component: OrderView,
  },
  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "login" */ "@/views/LoginView.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, _, next) => {
  const isAuthenticated = Boolean(getAccessToken());

  if (to.name !== "login" && !isAuthenticated) {
    next({ name: "login" });
  } else {
    next();
  }
});

export default router;
