<template>
  <div class="page">
    <div class="md-layout md-gutter">
      <div v-for="app in apps" :key="app.id" class="md-layout-item md-size-33">
        <md-card>
          <md-card-content>
            <div class="md-layout md-gutter">
              <div class="md-layout-item md-size-50">
                <md-card-media>
                  <img
                    :src="`${$imagesBaseUrl}${app.image}`"
                    :alt="app.id"
                    @click="handleLightboxOpen(`${$imagesBaseUrl}${app.image}`)"
                  />
                </md-card-media>

                <md-card-header>
                  <div class="md-body-2 mb-10">
                    <b>Номер (ID)</b>: {{ app.id }}
                  </div>

                  <div v-if="app.orders?.[0]?.order_id" class="md-body-2">
                    <b>Номер заказа (ID)</b>: {{ app.orders[0].order_id }}
                  </div>
                  <div v-if="app.orders?.[0]?.status" class="md-body-2 mb-10">
                    <b>Статус заказа</b>:
                    <span
                      :class="`order-status-${app.orders[0].status.toLowerCase()}`"
                      >{{ app.orders[0].status }}</span
                    >
                  </div>

                  <div v-if="app.promotion?.id" class="md-body-2">
                    <b>Номер акции (ID)</b>: {{ app.promotion.id }}
                  </div>
                  <div v-if="app.promotion?.name_ru" class="md-body-2 mb-10">
                    <b>Название акции</b>: {{ app.promotion.name_ru }}
                  </div>

                  <div
                    v-if="app.user?.first_name || app.user?.last_name"
                    class="md-body-2"
                  >
                    <b>ФИО</b>:
                    {{
                      [app.user.first_name, app.user.last_name].join(" ").trim()
                    }}
                  </div>
                  <div v-if="app.user?.phone" class="md-body-2 mb-10">
                    <b>Телефон</b>:
                    <a :href="`https://t.me/${app.user.phone}`">{{
                      app.user.phone
                    }}</a>
                  </div>

                  <div class="md-body-2">
                    <b>Дата создания</b>:
                    {{ new Date(app.created_at).toLocaleString("ru-RU") }}
                  </div>
                </md-card-header>

                <md-card-actions>
                  <md-button
                    v-for="button in buttons"
                    :key="button.id"
                    :class="[
                      'md-raised',
                      button.accept ? 'md-primary' : 'md-accent',
                    ]"
                    :disabled="updatingApps[app.id] === button.id"
                    @click="() => handleClickButton(app.id, button.id)"
                    >{{
                      updatingApps[app.id] === button.id
                        ? "Подождите..."
                        : button.name
                    }}</md-button
                  >
                </md-card-actions>
              </div>

              <div class="md-layout-item md-size-50">
                <ul v-if="app.orders" class="products">
                  <li
                    v-for="(product, productIndex) in app.orders"
                    :key="productIndex"
                  >
                    <b>{{ product.point_id }}</b> - {{ product.name }}
                  </li>
                </ul>
              </div>
            </div>
          </md-card-content>
        </md-card>
      </div>

      <vue-easy-lightbox
        escDisabled
        moveDisabled
        :visible="lightboxVisible"
        :imgs="lightboxImgs"
        :index="lightboxIndex"
        @hide="handleLightboxHide"
      ></vue-easy-lightbox>
    </div>
  </div>
</template>

<script>
import { API_APPS_URL, API_BUTTONS_URL, API_REFRESH_URL } from "@/constants";
import {
  getAccessToken,
  getRefreshToken,
  removeAccessToken,
  removeRefreshToken,
  setAccessToken,
} from "@/helpers";

export default {
  name: "OrderView",
  data: () => ({
    appsIntervalId: null,
    apps: [],
    buttons: [],
    updatingTokens: false,
    updatingApps: {},
    lightboxVisible: false,
    lightboxImgs: "",
    lightboxIndex: 0,
  }),
  methods: {
    handleLightboxOpen(image) {
      this.lightboxImgs = image;
      this.lightboxVisible = true;
    },
    handleLightboxHide() {
      this.lightboxVisible = false;
    },
    updateTokens() {
      const refreshToken = getRefreshToken();

      if (!refreshToken) {
        removeAccessToken();
        removeRefreshToken();

        if (this.$route.name !== "login") {
          this.$router.push({ name: "login", replace: true }).catch(() => {});
        }

        return;
      }

      this.updatingTokens = true;

      this.$http
        .post(API_REFRESH_URL, { refresh: refreshToken })
        .then((response) => {
          const { access } = response.data;

          setAccessToken(access);

          this.updatingTokens = false;
        })
        .catch(() => {
          removeAccessToken();
          removeRefreshToken();

          if (this.$route.name !== "login") {
            this.$router
              .push({ name: "login", replace: true })
              .then(() => {
                this.updatingTokens = false;
              })
              .catch(() => {});
          } else {
            this.updatingTokens = false;
          }
        });
    },
    handleClickButton(appId, buttonId) {
      if (this.updatingTokens || Object.keys(this.updatingApps).length) {
        return;
      }

      const accessToken = getAccessToken();

      this.$set(this.updatingApps, appId, buttonId);

      this.$http
        .put(
          `${API_APPS_URL}/${appId}`,
          { button_id: buttonId },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        )
        .then(() => {
          this.fetchApps();

          if (this.updatingApps[appId]) {
            this.$delete(this.updatingApps, appId, buttonId);
          }
        })
        .catch((error) => {
          if (this.updatingApps[appId]) {
            this.$delete(this.updatingApps, appId, buttonId);
          }

          if (error.response.status === 401) {
            this.updateTokens();
          }
        });
    },
    fetchButtons() {
      if (this.updatingTokens) {
        return;
      }

      const accessToken = getAccessToken();

      this.$http
        .get(API_BUTTONS_URL, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
        .then((response) => {
          this.buttons = response.data;
        })
        .catch((error) => {
          if (error.response.status === 401) {
            this.updateTokens();
          }
        });
    },
    fetchApps() {
      if (this.updatingTokens) {
        return;
      }

      const accessToken = getAccessToken();

      this.$http
        .get(`${API_APPS_URL}/`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
        .then((response) => {
          this.apps = response.data;
        })
        .catch((error) => {
          if (error.response.status === 401) {
            this.updateTokens();
          }
        });
    },
  },
  mounted() {
    this.fetchButtons();
    this.fetchApps();

    this.appsIntervalId = setInterval(this.fetchApps, 15 * 1000);
  },
  destroyed() {
    if (this.appsIntervalId) {
      clearInterval(this.appsIntervalId);
    }
  },
};
</script>

<style lang="scss" scoped>
.page {
  padding: 30px;
}

.container {
  max-width: 630px;
  padding: 0 15px;
  margin: 0 auto;
}

.mb-10 {
  margin-bottom: 10px;
}

.md-card {
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
}

.md-card-media img {
  cursor: pointer;
}

.md-card .md-title {
  margin-bottom: 5px;
}

.md-card .md-subhead {
  margin-bottom: 10px;
}

.md-card-header {
  padding-left: 0;
  padding-right: 0;
}

.md-card-actions {
  flex-direction: column;
  padding-left: 0;
  padding-right: 0;

  .md-button {
    width: 100%;
    margin: 0 0 10px 0;

    &:last-child {
      margin-bottom: 0;
    }

    + .md-button {
      margin-left: 0;
    }
  }
}

.order-status-processing {
  color: #448aff;
}

.order-status-canceled {
  color: #ff5252;
}

.order-status-to_withdraw {
  color: #04aa6d;
}

.products {
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
