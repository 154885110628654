import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import {
  MdButton,
  MdContent,
  MdCard,
  MdList,
  MdProgress,
  MdField,
} from "vue-material/dist/components";
import "vue-material/dist/vue-material.min.css";
import "vue-material/dist/theme/default.css";
import axios from "axios";
import VueAxios from "vue-axios";
import VueEasyLightbox from "vue-easy-lightbox";

Vue.config.silent = true;

Vue.use(VueAxios, axios);
Vue.use(VueEasyLightbox);

Vue.use(MdContent);
Vue.use(MdCard);
Vue.use(MdList);
Vue.use(MdProgress);
Vue.use(MdField);
Vue.use(MdButton);

Vue.prototype.$imagesBaseUrl = process.env.VUE_APP_IMAGES_BASE_URL;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
